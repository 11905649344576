import React from "react";

const YoutubeModal = ({visible, onClose}) => {

    const handleClose = (e) => {
        if(e.target.id === "container")
            onClose();
    }
    if(visible)
    return (
        <div id="container" onClick={()=>{handleClose}} className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center ">
            <iframe className="w-full h-full md:w-[75vw] md:h-[75vh]" src="https://www.youtube.com/embed/vk68InxWE00?si=x3-2arYBhOskTrT9" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>)
}

export default YoutubeModal;