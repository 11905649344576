import React from "react"
import { SelectFields, TextField } from "../utils/FormFields"

const CreateApp = ({ createApp, createdApp, formData, handleChange, isAppCreated,isAppCreating, handleSubmit, setCreateApp, errors }: any) => {
    if (createApp) {
        return (
            <div>
                <h2 className="text-2xl ml-0 m-5">Create new App</h2>
                <div className="p-6 bg-white rounded shadow-md">

                    <div className="space-y-4 m-0">
                        <TextField label={"App Name"} name={"name"} value={formData.name} onChange={handleChange} errors={errors} />
                        <SelectFields
                            label={"App Type"} name={"type"} value={formData.type} onChange={handleChange}
                            options={[
                                { label: "1: Topic - SubTopics", value: 1 },
                                { label: "2: Classwise Type 1", value: 2 },
                                { label: "3: PDFs", value: 3 }
                            ]}
                        />
                        <TextField label={"Description"} name={"description"} value={formData.description} onChange={handleChange} errors={errors} />
                        {isAppCreating ?
                            `Creating App: ${formData.name}` :
                            <div className="flex w-full justify-end gap-1">
                                <button
                                    className=" bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                    onClick={() => { handleSubmit() }}
                                >
                                    Submit
                                </button>
                                <button
                                    className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                                    onClick={() => { setCreateApp(false) }}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (<div className="flex gap-2 items-center">
            <button className="bg-blue-600 text-white hover:bg-blue-800 px-4 py-1 rounded-md"
                onClick={() => { setCreateApp(true) }}
            >Create New App</button>
            <div>
                {
                    isAppCreated && (createdApp !== null ?
                    `App Created: ${createdApp}`
                    : 'App Creation Failed!')
                }
            </div>
        </div>)
    }
}

export default CreateApp;