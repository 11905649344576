import React from "react"

export type tSelectFields = {
    label: string,
    name?: string,
    value: any,
    options: any[],
    onChange: any,
    [key: string]: any
}

export type tTextField = {
    label: string,
    name: string,
    value: string | number,
    onChange: (e)=>void,
    errors: any;
    [key: string]: any
}

export const FormRowWrapper = ({ children }) => {
    return (
        <div className="flex items-center space-x-4 w-full">
            {children}
        </div>
    )
}

export const FormRowLabel = ({ children }) => {
    return <label className="min-w-[120px] block text-sm font-medium text-gray-700 mb-1">
        {children}
    </label>
}

export const TextField = ({ label, name, value, onChange, errors, ...props }: tTextField) => {
    return (
        <FormRowWrapper>
            <FormRowLabel>
                {label}
            </FormRowLabel>
            <input
                id="textField1"
                name={name ? name : label}
                value={value}
                onChange={onChange}
                placeholder={`Enter ${label}`}
                // className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                className={`flex-1 p-2 border rounded ${errors[name] ? 'border-red-500' : ''} focus:outline-none focus:ring-2 focus:ring-blue-400`}
                required
                {...props}
            />
            {errors[name] && (
                <p className="text-red-500 text-sm">{errors[name]}</p>
            )}
        </FormRowWrapper>
    );
};


export const SelectFields = ({ label, name, value, options, onChange, ...props }: tSelectFields) => {
    return (
        <FormRowWrapper>
            <FormRowLabel>
                {label}
            </FormRowLabel>
            <select
                name={name ? name : label}
                value={value}
                onChange={onChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                {...props}
            >
                {
                    options.map((optionData, index) => {
                        return <option value={optionData.value} key={index}>{optionData.label}</option>
                    })
                }
            </select>
        </FormRowWrapper>
    )
};