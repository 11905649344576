import React from "react";

const Modal = ({visible, onClose, content}) => {

    const handleClose = (e) => {
        if(e.target.id === "modalContainer")
            onClose();
    }
    if(visible)
    return (
        <div id="modalContainer" onClick={handleClose} className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center ">
            {content}
        </div>)
}

export default Modal;