import React from "react";
import { Link } from "react-router-dom";

const ChapterAccordions = ({docData, kannada, topicsList}:any) => {

    if (topicsList.sub_id === undefined || topicsList.sub_id === null) {
        return (
            <ul className="overflow-hidden" >
                {
                    topicsList.map((docTopic: any, topicIndex: number) => {
                        return (
                            <Link to={"/topic"} state={{
                                topicData: docTopic,
                                appId: docData.appId
                            }}
                                key={docData.chapter_id + "_" + docTopic.id + (docTopic.sub_id && docTopic.sub_id)}>
                                <li className="pl-5 hover:bg-gray-300 text-gray-800 font-medium py-2 w-full text-start">
                                    {topicIndex + 1}. {docTopic.name}
                                </li>
                            </Link>
                        )
                    })
                }
            </ul>
        )
    } else {
        return (
            <>
                <ul className="overflow-hidden">
                    <div className="text-lg font-semibold pl-5 text-ellipsis  text-gray-800  py-2 w-full text-start overflow-hidden cursor-default">{kannada?"ಪ್ರಾಯೋಗಿಕ ಕಲಿಕೆ":"Practical"}</div>
                    {
                        topicsList.filter((topic) => topic.sub_id == 1).map((docTopic, topicIndex: number) => {
                            return (
                                <Link to={"/topic"} state={{
                                    topicData: docTopic,
                                    parentPath: docData.GGBPath,
                                    isKannada: kannada
                                }}
                                    key={docData.chapter_id + "_" + docTopic.id + "_" + "sub1"}

                                >
                                    <li className="pl-10 hover:bg-gray-300 text-gray-800 font-medium py-2 w-full text-start" >
                                        {topicIndex + 1}. {docTopic.name}
                                    </li>
                                </Link>

                            )
                        })
                    }
                    <div className=" pl-5 text-lg text-ellipsis text-gray-800 font-semibold py-2 w-full text-start overflow-hidden cursor-default">{kannada? "ತಾರ್ಕಿಕ ಸಾಧನೆ":"Theoretical Proofs"}</div>
                    {
                        topicsList.filter((topic) => topic.sub_id == 2).map((docTopic, topicIndex: number) => {
                            return (
                                <Link to={"/topic"} state={{
                                    topicData: docTopic,
                                    parentPath: docData.GGBPath,
                                    isKannada: kannada
                                }}
                                    key={docData.chapter_id + "_" + docTopic.id}
                                >
                                    <li className=" pl-10 hover:bg-gray-300 text-gray-800 font-medium py-2 w-full text-start" >
                                        {topicIndex + 1}. {docTopic.name}
                                    </li>
                                </Link>
                            )
                        })
                    }
                </ul>
            </>
        )
    }
};

export default ChapterAccordions;