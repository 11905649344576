import { useState } from "react";
import { createDoc } from "../../firestore/createDoc";

const useCreateApp = () => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [createdApp, setCreatedApp] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateAppCollection = async (data: any) => {
    try {
      setLoading(true);
      setCreatedApp(null);
      const addApp = await createDoc("apps", data);
      setCreatedApp(data.name);
      setIsUpdated(true);
    } catch (error) {
      setCreatedApp(null);
      console.log("Error creating app");
    } finally {
      setLoading(false);
    }
  };

  return { isUpdated, createdApp, updateAppCollection, loading };
};

export default useCreateApp;
