import React, { useEffect, useState } from "react";
import GeoGebra from 'react-geogebra';
import { useLocation, useNavigate } from "react-router-dom";
import useGetGGBFile from "../hooks/useGetGGBFile";
import Home from "./Home.tsx";
import NotSubscribedModal from "../components/utils/NotSubscribedModal.tsx";

const TopicPage = () => {

    let { state } = useLocation();
    
    const navigate = useNavigate();
    const { getGGBFileData, GGBFile, loading } = useGetGGBFile();
    const [subModal, setSubModal] = useState<boolean>(false)
    console.log(state);
    console.log(GGBFile)
    useEffect(() => {
        if (state)
            getGGBFileData(state?.topicData.GGBPath);
    }, [state]);

    useEffect(() => {
        if (GGBFile === "Not Subscribed!")
            setSubModal(true)
    }, [GGBFile])


    return (
        <>

            <div className="flex overflow-auto flex-col w-[100vw] h-[90vh]">
                {subModal && GGBFile === "Not Subscribed!" && <NotSubscribedModal visible={subModal} onClose={() => { setSubModal(false); navigate("/"); }} />}
                <div className=" h-[675px]">
                    {(loading && GGBFile !== "Not Subscribed!") ? "Loading ..." :
                        <GeoGebra
                            appName="sample"
                            width={1600}
                            height={675}
                            showToolBar={false}
                            showAlgebraInput={false}
                            showMenuBar={false}
                            showFullscreenButton
                            showZoomButtons
                            showResetIcon
                            filename={GGBFile} appletOnLoad={function (): void {
                                throw new Error("Function not implemented.");
                            }} id={""} />
                    }
                </div>
                <div className="md:hidden">
                    <Home isSub={true} />
                </div>

            </div>
        </>
    )
}

export default TopicPage;