import React, { useEffect, useState } from "react";
import { SelectFields, TextField } from "../utils/FormFields";
import useUploadFile from "../../hooks/Admin/useUploadFile";
import useDeleteDocument from "../../hooks/Admin/useDeleteDocument";

const UpdateTopic = ({ updateChapterCollection, selectedTopic, topicsLoading, topicsList, onTopicChange, memoizedTopicList, selectedChapter }) => {
    const [editOn, setEditOn] = useState(false);
    const [createTopic, setCreateTopic] = useState(false);
    const [newName, setNewName] = useState('');
    const [newOrder, setNewOrder] = useState<number>(0);
    const [ggbFile, setggbFile] = useState<any>(null);
    const [errors, setErrors] = useState({});

    const { uploadFile, loading } = useUploadFile();
    const { deleteFlag, deleteChapter, loading: loadingDelete } = useDeleteDocument("topics");


    const handleChange = (e) => {
        setNewName((prevData) => (e.target.value));
    };

    const handleDeleteTopic = () => {
        deleteChapter(selectedTopic.id);
    }

    useEffect(() => {
        console.log("Deleted Successfully");
    }, [deleteFlag]);

    const handleUpdateTopic = async () => {
        let newErrors = {}
        if (!newName) {
            newErrors["newTopicName"] = "*required"
        } else if (!newOrder) {
            newErrors["newOrder"] = "*required"
        } else if (ggbFile == null && createTopic) {
            newErrors["ggbFile"] = "*required"
        } else {
            setCreateTopic(false);
            setEditOn(false);
            let timestamp = +new Date();
            await uploadFile(timestamp.toString(), ggbFile);
            const newData = {};
            newName ? newData["name"] = newName : null;
            newOrder ? newData["order"] = newOrder : null;
            ggbFile ? newData["GGBPath"] = timestamp.toString() : newData["GGBPath"] = selectedTopic.GGBPath;
            updateChapterCollection({
                ...newData,
                "chapterId": selectedChapter.id
            })
        }
        setErrors(newErrors);
    }

    const handleEditOn = () => {
        setEditOn(true);
        setNewName(selectedTopic.name);
        setNewOrder(selectedTopic.order);
    }


    return (
        <div className="space-y-4 m-0">
            <div className="flex gap-2">
                {
                    !topicsLoading && topicsList.length > 0 && selectedTopic != null &&
                    <SelectFields
                        label="Topics"
                        name="topics"
                        onChange={onTopicChange}
                        value={selectedTopic.name}
                        options={memoizedTopicList}
                        disabled={editOn}
                    />
                }

                <button
                    className="bg-red-600 text-white p-2 rounded hover:bg-red-700 text-nowrap text-center"
                    onClick={() => {
                        setCreateTopic(false);
                        setEditOn(false);
                        handleDeleteTopic();
                    }}
                >
                    Delete
                </button>
                <button
                    className="bg-slate-600 text-white p-2 rounded hover:bg-slate-700 text-nowrap text-center"
                    onClick={() => {
                        setCreateTopic(false);
                        handleEditOn();
                    }}
                >
                    Edit
                </button>

                <button
                    className="bg-green-600 text-white p-2 rounded hover:bg-green-700 text-nowrap text-center"
                    onClick={() => {
                        setCreateTopic(true);
                        setEditOn(false);
                    }}
                >
                    + Add new topic
                </button>

            </div>
            {
                loading && "updating..."
            }
            {
                (editOn || createTopic) && (
                    <div className="mx-6">
                        <div className="text-xl ml-0 m-5">{
                            editOn ? `Editing ${selectedTopic.name}` : "Create New Topic"
                        }</div>
                        <div className="mx-4 space-y-2">
                            <TextField label={"Topic Name"} name={"newTopicName"} value={newName} onChange={handleChange} errors={errors} />
                            <TextField label={"New Order Number"} name={"newOrder"} value={newOrder} onChange={(e) => { setNewOrder(Number(e.target.value)) }} errors={errors} type={"number"} />
                            <div className="flex gap-6">
                                <input
                                    type="file"
                                    onChange={(e: any) => setggbFile(e.target.files[0])}
                                    className="custom-file-input border border-black px-4 py-1 rounded-md cursor-pointer file:mr-4 file:py-1 file:px-4 file:rounded-md file:border file:border-black file:bg-slate-200 file:text-black hover:file:bg-slate-400"
                                />
                                {errors["ggbFile"] && (
                                    <p className="text-red-500 text-sm">{errors["ggbFile"]}</p>
                                )}
                            </div>
                            <div className="flex w-full justify-end gap-1">
                                <button
                                    className=" bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                    onClick={() => { handleUpdateTopic(); }}
                                >
                                    {editOn ? "Update Topic" : "Create Topic"}
                                </button>
                                <button
                                    className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                                    onClick={() => {
                                        setCreateTopic(false);
                                        setEditOn(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )

}

export default UpdateTopic;
