import { useState } from "react";
import axios from "axios";
import { UserAuth } from "../context/AuthContext";

const useGetGGBFile = () => {
  const [GGBFile, setGGBFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { sessionToken } = UserAuth();
  const getGGBFileData = async (filePath: string) => {
    try {
      let path;
      setLoading(true);
      path = "GGBFiles/" + filePath;
      const ggbFileURL = await axios.get(
        process.env.FIREBASE_GET_GEOGEBRA_APPLET + "/" + path,
        {
          // process.env.FIREBASE_FUNCTION_URL + "/geogebraApplets/"+path,{

          headers: {
            Authorization: "Bearer " + sessionToken,
          },
        }
      );

      setGGBFile(ggbFileURL.data);
    } catch (error) {
      if (error.response.status === 401) {
        setGGBFile(error.response.data);
      }
      console.log("Error fetching GGB file");
    } finally {
      setLoading(false);
    }
  };

  return { getGGBFileData, GGBFile, loading };
};

export default useGetGGBFile;
