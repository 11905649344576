import {
  getCollectionData,
  getCollectionRef,
  queryType,
} from "./basicOperations";

const getDocsList = async (collection, queries: queryType) => {
  const appCollectionRef = await getCollectionRef(collection);
  return await getCollectionData(appCollectionRef, queries);
};

export default getDocsList;
