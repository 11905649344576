import axios from "axios";
import { useState } from "react";
import updateDocumentbyID from "../../firestore/updateDocumentbyID";

const useUpdateDocument = (collection) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [updateFlag, setIsUpdateFlag] = useState<boolean>(true);
  const updateDocument = async (chapterId, newData) => {
    try {
      setIsSuccess(true);
      setLoading(true);
      await updateDocumentbyID(collection, chapterId, newData);
      setIsUpdateFlag((prev)=> !prev);
    } catch (error) {
      setIsSuccess(false);
      console.log("Error updating file");
    } finally {
      setLoading(false);
    }
  };

  return { updateDocument, loading, isSuccess, updateFlag };
};

export default useUpdateDocument;
