import { useState } from "react";
import { createDoc } from "../../firestore/createDoc";

const useCreateTopic = () => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [createdTopic, setCreatedTopic] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);

  const updateTopicCollection = async (data: any) => {
    try {
      setLoading(true);
      setIsUpdated(false);
      setCreatedTopic(null);
      await createDoc("topics", data);
      setCreatedTopic(data.name);
    } catch (error) {
      setCreatedTopic(null);
      console.log("Error creating topic");
    } finally {
      setIsUpdated(true);
      setLoading(false);
      setUpdateFlag((prev)=> !prev);
    }
  };

  return { isUpdated, createdTopic, updateTopicCollection, loading, updateFlag };
};

export default useCreateTopic;
