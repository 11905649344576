import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import useGetApps from "../hooks/useGetAppList";
import App from "../components/Apps/App";
import Modal from "../components/utils/Modal";
import { AppsContext } from "../context/AppsContext";



const Apps = () => {

    const [classSelectionModal, setClassSelectionModal] = useState<boolean>(false);
    const [appData, setAppData] = useState({});
    const navigate = useNavigate();

    const { setApp, apps, setApps } = useContext(AppsContext);

    console.log(apps);

    const handleAppClick = (selectedAppData) => {
        setAppData(selectedAppData);
        if (selectedAppData.type == 2) {
            setClassSelectionModal(true);
        } else {
            setApp(selectedAppData);
            navigate("/math", { state: selectedAppData });
        }
    }
    const { getAppsList, chaptersList: appsList, loading } = useGetApps();

    useEffect(() => {
        if (apps.length === 0) {
            getAppsList([]);
        }
    }, []);

    useEffect(()=>{
        if(!loading && appsList.length > 0){
            console.log("did this")
            setApps(appsList);
        }
    },[loading]);

    const ClassSelector = () => {
        const ClassLi = ({ classNumber, color }) => {

            return (
                <div
                    className={`rounded-md p-10 ${color} text-white`}
                >{classNumber}</div>
            )
        }
        return (
            <div className="bg-customBg p-2 rounded-md">
                <div
                    className="flex gap-5"
                >
                    <Link
                        to={"/math"}
                        onClick={() => {
                            setApp({
                                ...appData,
                                class: 8
                            })
                        }}
                    >
                        <ClassLi classNumber={"8th"} color={"bg-customBlue"} />
                    </Link>
                    <Link
                        to={"/math"}
                        onClick={() => {
                            setApp({
                                ...appData,
                                class: 9
                            })
                        }}
                    >
                        <ClassLi classNumber={"9th"} color={"bg-customAmber"} />
                    </Link>
                    <Link
                        to={"/math"}
                        onClick={() => {
                            setApp({
                                ...appData,
                                class: 10
                            })
                        }}
                    >
                        <ClassLi classNumber={"10th"} color={"bg-customSalmon"} />
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div className="p-16 h-[90vh] w-full bg-customBg overflow-scroll">
            <Modal visible={classSelectionModal} onClose={() => setClassSelectionModal(false)} content={<ClassSelector />} />
            <div className="font-bold text-xl mb-5">Apps</div>
            <div className="flex flex-wrap justify-around gap-5">
                {loading ? "Loading..." :
                    apps.map((app, index) => {
                        return <App
                            handleAppClick={handleAppClick}
                            appData={app}
                            key={index}
                        />
                    })

                }
            </div>
        </div>
    )
}

export default Apps;