import { getCollectionRef, updateDocument } from "./basicOperations";

const updateDocumentbyID = async (collection, docId, newData) => {
  const collectionRef = await getCollectionRef(collection);
  await updateDocument(
    collectionRef,
    docId,
    newData
  );
  
};

export default updateDocumentbyID;
