import { db, storage } from "../pages/firebase";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  limit,
  addDoc,
  WhereFilterOp,
  updateDoc,
  deleteDoc
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
} from "firebase/storage";

export type queryType = [string, WhereFilterOp, string|number][]

export const getCollectionRef = async (collectionName: string) => {
  const collectionRef = collection(db, collectionName);
  return collectionRef;
};

export const updateDocument = async (collectionRef, docId: string, newData: any) => {
  const docRef = await doc(collectionRef,"/"+docId);
  await updateDoc(docRef,newData);
}

export const deleteDocument = async (collectionRef, docId: string) => {
  const docRef = await doc(collectionRef,"/"+docId);
  await deleteDoc(docRef);
}

export const getUserDoc = async (collection, email: string) => {
  const q = query(collection, where("email", "==", email), limit(1));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size === 1) {
    return querySnapshot.docs;
  } else {
    return null;
  }
};

export const addToCollection = async (collectionRef, data) => {
  return await addDoc(collectionRef, data);
};

export const getCollectionData = async (collectionRef, queries?: queryType) => {
  let docList: any = [];
  if (!queries) {
    const rawList = await getDocs(collectionRef);
    rawList.docs.forEach((doc: any) => {
      docList.push({...doc.data(), id: doc.id});
    });
  } else {
    const q = query(collectionRef, ...queries.map((aQuery)=>{
      return where(aQuery[0],aQuery[1],aQuery[2])
    }));
    const rawList = await getDocs(q);
    rawList.docs.forEach((doc: any) => {
      docList.push({...doc.data(), id: doc.id});
    });
  }
  return docList;
};

export const getStorageReference = async (filePath: string) => {
  try {
    const storageRef = ref(storage, "MathUnlimited/" + filePath);
    return storageRef;
  } catch (e) {
    console.log("Error fetching storage Ref", e);
    return null;
  }
};

export const getStorageURL = (storageRef) => {
  return getDownloadURL(storageRef);
};

export const deleteStorageFile = async (storageRef) => {
  try {
    const deletedObject = await deleteObject(storageRef);
  } catch (e) {
    return e.code;
  }
  return deleteObject;
};

export const uploadStorageFile = async (storageRef, file) => {
  // const uploadFile = uploadBytes(storageRef, file);
  const metadata = {
    contentType: "application/octet-stream",
  };
  const uploadFile = await uploadBytes(storageRef, file, metadata);
};
