import React from "react";



// Array of custom color class names 
const customColorClasses = ['bg-customAmber', 'bg-customSalmon', 'bg-customTeal', 'bg-customPink', 'bg-customViolet', 'bg-customBlue']; 
// Function to get a random class name 
const getRandomColorClass = () => { const randomIndex = Math.floor(Math.random() * customColorClasses.length); return customColorClasses[randomIndex]; };

const App = ({handleAppClick, appData}: any) => {
    return (
        <div onClick={()=>{handleAppClick(appData)}} className={`flex flex-col gap-2 rounded-md w-52 justify-between p-5 min-w-64 mb-2 cursor-default ${getRandomColorClass()} hover:brightness-105 text-white`}>
            <div className="font-semibold">
                {appData.name}
            </div>
            <div className="">
                {appData.description}
                {appData.type === 1 && "Ace your SSLC exams effortlessly with Secure 60! Our focused app guarantees a solid 60 marks when you complete the tailored courses. Spend just 75 minutes for a quick and effective revision, ensuring you're well-prepared for success."}
            </div>
            <div >
                <button className="bg-green-500 rounded-md px-4 py-1">
                    Enroll and Start
                </button>
            </div>
        </div>
    )
}

export default App;