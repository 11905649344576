import { useState } from "react";
import { queryType } from "../firestore/basicOperations";
import getDocsList from "../firestore/getDocsList";

const useGetChapters = () => {
  const [chaptersList, setChaptersList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getChaptersList = async (queries: queryType) => {
    try {
      setLoading(true);

      const data = await getDocsList("newChapters", queries);
      setChaptersList(data);
    } catch (error) {
      // if(error.response.status === 401){
      //   setChaptersList(error.response.data)
      // }
      console.log("Error fetching chapters");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { getChaptersList, chaptersList, loading };
};

export default useGetChapters;
