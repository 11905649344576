import { useState } from "react";
import updateChapterGGB from "../../firestore/updateChapterGGB";

const useUploadFile = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const uploadFile = async (
    filePath: string,
    fileData: any
  ) => {
    try {
      setLoading(true);
      await updateChapterGGB(filePath,fileData);
      
      console.log("File updated Successfully!");
    } catch (error) {
      console.log("Error updating file");
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, loading }
};

export default useUploadFile;
