import React, { useEffect, useState } from "react";
import { SelectFields, TextField } from "../utils/FormFields";

const UpdateChapter = ({ chaptersLoading, chaptersList, onChapterChange, selectedChapter, memoizedChapterList, selectedApp, selectedLanguage, selectedClass, isChapterAdded, createdChapter, updateChapterCollection, loading, updateChapter, deleteChapter }) => {

    const [createChapter, setCreateChapter] = useState(false);
    const [editOn, setEditOn] = useState(false);
    const [newName, setNewName] = useState('');
    const [newOrder, setNewOrder] = useState<number>(0);
    const [messageFlag, setMessageFlag] = useState<boolean>(false);

    const [errors, setErrors] = useState({
        newChapterName: '',
        newOrder: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewName((prevData) => (value));
    };


    const handleCreateChapter = () => {
        if (!newName) {
            setErrors({
                ...errors,
                newChapterName: "*required"
            })
        } else if (!newOrder) {
            setErrors({
                ...errors,
                newOrder: "*required"
            })
        } else {
            let tempForm = {};

            if (selectedApp.type === 2 || selectedApp.type === 3) {
                tempForm["grade"] = Number(selectedClass);
            }
            updateChapterCollection({
                appId: selectedApp.id,
                name: newName,
                language: selectedLanguage,
                order: newOrder,
                ...tempForm
            })
        }
    }

    const handleUpdateChapter = () => {
        updateChapter(selectedChapter.id, {
            ...selectedChapter,
            order: newOrder,
            name: newName
        })
    }

    const handleDeleteChapter = () => {
        deleteChapter(selectedChapter.id);
    }

    useEffect(() => {
        if (selectedChapter != null) {
            setNewOrder(selectedChapter.order);
        }
    }, [selectedChapter]);

    useEffect(()=>{
        if(!loading){
            setMessageFlag(true);
            setTimeout(() => {
                setMessageFlag(false);
            }, 5000);
        }
    },[isChapterAdded])

    return (
        <div className="space-y-4 m-0">
            <div className="flex gap-2">
                {
                    !chaptersLoading && chaptersList.length > 0 && selectedChapter != null &&
                    <SelectFields
                        label="Chapters"
                        name="chapters"
                        onChange={onChapterChange}
                        value={selectedChapter.name}
                        options={memoizedChapterList}
                        disabled={editOn}
                    />
                }
                <div className="flex gap-2">
                    {chaptersList.length > 0 ?
                        <div className="flex gap-2">
                            <button
                                className="bg-red-600 text-white p-2 rounded hover:bg-red-700 text-nowrap text-center"
                                onClick={() => {
                                    setCreateChapter(false);
                                    setEditOn(false);
                                    handleDeleteChapter();
                                }}
                            >
                                Delete
                            </button>
                            <button
                                className="bg-slate-600 text-white p-2 rounded hover:bg-slate-700 text-nowrap text-center"
                                onClick={() => {
                                    setCreateChapter(false);
                                    setNewName(selectedChapter.name);
                                    setNewOrder(selectedChapter.order);
                                    setEditOn(true);
                                }}
                            >
                                Edit
                            </button>
                        </div>
                        : ''
                    }
                    <button
                        className="bg-green-600 text-white p-2 rounded hover:bg-green-700 text-nowrap text-center"
                        onClick={() => {
                            setCreateChapter(true);
                            setEditOn(false);
                        }}
                    >
                        + Add new chapter
                    </button>
                </div>

            </div>
            {
                editOn && (
                    <div className="mx-6">
                        <div className="text-xl ml-0 m-5">Update Chapter</div>
                        <div className="mx-4 space-y-2">
                            <TextField label={"New Chapter Name"} name={"newChapterName"} value={newName} onChange={handleChange} errors={errors} />
                            <TextField label={"New Order Number"} name={"newOrder"} value={newOrder} onChange={(e) => { setNewOrder(Number(e.target.value)) }} errors={errors} type={"number"} />
                            <div className="flex w-full justify-end gap-1">
                                <button
                                    className=" bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                    onClick={() => { handleUpdateChapter(); setCreateChapter(false); setEditOn(false); }}
                                >
                                    Update Chapter
                                </button>
                                <button
                                    className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                                    onClick={() => {
                                        setCreateChapter(false);
                                        setEditOn(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>

                )
            }
            {
                createChapter && (
                    <div className="mx-6">
                        <div className="text-xl ml-0 m-5">Create New Chapter</div>
                        <div className="mx-4 space-y-2">
                            <TextField label={"Chapter Name"} name={"newChapterName"} value={newName} onChange={handleChange} errors={errors} />
                            <TextField label={"New Order Number"} name={"newOrder"} value={newOrder} onChange={(e) => { setNewOrder(Number(e.target.value)) }} errors={errors} type={"number"} />
                            <div className="flex w-full justify-end gap-1">
                                <button
                                    className=" bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
                                    onClick={() => { handleCreateChapter(); setCreateChapter(false); setEditOn(false); }}
                                >
                                    Create Chapter
                                </button>
                                <button
                                    className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                                    onClick={() => {
                                        setCreateChapter(false);
                                        setEditOn(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                (!loading && isChapterAdded) &&
                (createdChapter === null ?
                    "Failed to create chapter" :
                    `Chapter Created: ${createdChapter}`)
            }
        </div >
    )
}

export default UpdateChapter;
