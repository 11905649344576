import React, { useEffect, useMemo, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import useGetChapters from "../hooks/useGetChapters";
import useGetApps from "../hooks/useGetAppList";
import useCreateApp from "../hooks/Admin/useCreateApp";
import { queryType } from "../firestore/basicOperations";
import { SelectFields } from "../components/utils/FormFields";
import CreateApp from "../components/Admin/CreateApp";
import UpdateChapter from "../components/Admin/UpdateChapter";
import useCreateChapter from "../hooks/Admin/useCreateChapter";
import useUpdateDocument from "../hooks/Admin/useUpdateDocument";
import useDeleteDocument from "../hooks/Admin/useDeleteDocument";
import useGetTopics from "../hooks/useGetTopics";
import UpdateTopic from "../components/Admin/UpdateTopic";
import useCreateTopic from "../hooks/Admin/useCreateTopic";


const Admin = () => {
    const { user } = UserAuth();

    const { getAppsList, chaptersList: appsList, loading: appLoading } = useGetApps();
    const { getChaptersList, chaptersList, loading: chaptersLoading } = useGetChapters();
    const { getTopicsList, topicsList, loading: topicsLoading } = useGetTopics();
    const { isUpdated: isAppCreated, createdApp, updateAppCollection, loading: isAppCreating } = useCreateApp();
    const { isUpdated: isChapterUpdated, createdChapter, updateChapterCollection, loading: isChapCreating } = useCreateChapter();
    const { isUpdated, createdTopic, updateTopicCollection, loading: isTopicCreating, updateFlag } = useCreateTopic();
    const { updateDocument, loading: isChapterUpdating, isSuccess: isUpdateSuccess, updateFlag: chapterUpdateFlag } = useUpdateDocument("newChapters");
    const { deleteFlag, deleteChapter, loading } = useDeleteDocument("newChapters");

    const [ggbFile, setggbFile] = useState<any>(null);
    const [createApp, setCreateApp] = useState(false);

    const [selectedApp, setSelectedApp] = useState<any>({ 'name': "Select an App" });
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [selectedClass, setSelectedClass] = useState<8 | 9 | 10>(8);
    const [selectedChapter, setSelectedChapter] = useState<any>(null);
    const [selectedTopic, setSelectedTopic] = useState<any>(null);
    const [selectedSubTopic, setSelectedSubTopic] = useState<any>(null);

    const [formData, setFormData] = useState({
        name: '',
        type: 1,
        description: ''
    });

    const [chapterForm, setChapterForm] = useState({
        name: '',
        order: 1
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getAppsList([]);
    }, []);

    useEffect(() => {
        if (isAppCreating === false) {
            setCreateApp(false);
            getAppsList([]);
        }
    }, [isAppCreating]);

    useEffect(() => {
        if (appsList.length > 0) {
            setSelectedApp(appsList[0])
        }
    }, [appsList]);

    useEffect(() => {
        let newQuery: queryType = [];
        newQuery.push(["language", "==", selectedLanguage]);
        if (selectedApp?.id) {
            newQuery.push(["appId", "==", selectedApp.id]);
        }
        if (selectedApp?.type && (selectedApp.type === 2 || selectedApp.type === 3)) {
            newQuery.push(["grade", "==", Number(selectedClass)]);
        }
        getChaptersList(newQuery);
    }, [selectedApp, selectedLanguage, selectedClass, isChapterUpdated, chapterUpdateFlag, deleteFlag]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors["name"] = '*required';
        if (!formData.description) newErrors["description"] = '*required';
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "type" ? Number(value) : value,
        }));
    };

    const onAppListChange = (e) => {
        const { value } = e.target;
        setSelectedApp(appsList.find((app) => app.name === value));

    }

    const onChapterChange = (e) => {
        const { value } = e.target;
        const newSelectedChapter = chaptersList.find((chapter) => chapter.name === value)
        setSelectedChapter(newSelectedChapter);
        getTopicsList([["chapterId", "==", newSelectedChapter.id]])
    }

    const onTopicChange = (e) => {
        setSelectedTopic(topicsList.find((topic) => topic.name === e.target.value));
    }

    const handleSubmit = () => {
        const validations = validateForm();
        if (Object.keys(validations).length > 0) {
            setErrors(validations)
        } else {
            setErrors({});
            updateAppCollection(formData);
        }
    }

    // Memoizing the list rendering logic
    const memoizedAppList = useMemo(() => {
        return appsList.map((doc: any, index: number) => (
            { label: doc.name, value: doc.name }
        ));
    }, [appsList]);

    const memoizedChapterList = useMemo(() => {
        return chaptersList.sort((a, b) => a.order - b.order).map((doc: any, index: number) => (
            { label: doc.name, value: doc.name }
        ))
    }, [chaptersList]);

    const memoizedTopicList = useMemo(() => {
        return topicsList.sort((a, b) => a.order - b.order).map((doc: any, index: number) => (
            { label: doc.name, value: doc.name }
        ))
    }, [topicsList])


    useEffect(() => {
        if (chaptersList.length > 0) {
            setSelectedChapter(chaptersList[0]);
            getTopicsList([["chapterId", "==", chaptersList[0].id]])
        } else {
            setSelectedTopic(null);
            setSelectedChapter(null)
        }
    }, [chaptersList, updateFlag]);

    useEffect(() => {
        if (topicsList.length > 0) {
            setSelectedTopic(topicsList[0])
        } else {
            setSelectedTopic(null);
        }
    }, [topicsList]);


    if (user.email === 'onkarmadli@gmail.com' || user.email === "malinisamrudh@gmail.com") {
        return (
            <div className="p-5 flex flex-col gap-2">

                <CreateApp
                    createApp={createApp}
                    createdApp={createdApp}
                    formData={formData}
                    handleChange={handleChange}
                    isAppCreating={isAppCreating}
                    isAppCreated={isAppCreated}
                    handleSubmit={handleSubmit}
                    setCreateApp={setCreateApp}
                    errors={errors}
                />

                <h2 className="text-2xl ml-0 m-5">Update existing Apps</h2>
                {
                    appLoading ? "Loading..." :
                        <div className="space-y-4 m-0">
                            <SelectFields
                                label="Select an App"
                                name="AppList"
                                onChange={onAppListChange}
                                options={memoizedAppList}
                                value={selectedApp.name}
                            />
                            <SelectFields
                                label="Language"
                                name="AppLanguage"
                                onChange={(e) => { setSelectedLanguage(e.target.value); }}
                                options={[
                                    { label: 'Kannada', value: 'Kannada' },
                                    { label: 'English', value: 'English' }
                                ]}
                                value={selectedLanguage}
                            />
                            {
                                selectedApp?.type != 1 &&
                                <SelectFields
                                    label="Class/Grade"
                                    name="class/grade"
                                    onChange={(e) => {
                                        setSelectedClass(e.target.value);
                                    }}
                                    value={selectedClass}
                                    options={[
                                        { label: '8th', value: 8 },
                                        { label: '9th', value: 9 },
                                        { label: '10th', value: 10 },
                                    ]}
                                />
                            }
                            {
                                selectedApp != null && selectedApp.type != 3 &&
                                <UpdateChapter
                                    chaptersList={chaptersList}
                                    chaptersLoading={chaptersLoading}
                                    onChapterChange={onChapterChange}
                                    selectedChapter={selectedChapter}
                                    memoizedChapterList={memoizedChapterList}
                                    selectedApp={selectedApp}
                                    selectedLanguage={selectedLanguage}
                                    selectedClass={selectedClass}
                                    isChapterAdded={isChapterUpdated}
                                    createdChapter={createdChapter}
                                    updateChapterCollection={updateChapterCollection}
                                    loading={isChapCreating}
                                    updateChapter={updateDocument}
                                    deleteChapter={deleteChapter}
                                />
                            }
                            {
                                selectedChapter != null && selectedApp != null && selectedApp.type != 3 &&
                                <UpdateTopic
                                    updateChapterCollection={updateTopicCollection}
                                    selectedTopic={selectedTopic}
                                    topicsLoading={topicsLoading}
                                    topicsList={topicsList}
                                    onTopicChange={onTopicChange}
                                    memoizedTopicList={memoizedTopicList}
                                    selectedChapter={selectedChapter}
                                />
                            }
                            {
                                selectedApp != null && selectedApp.type == 3 &&
                                <></>

                            }
                        </div>
                }
                {/* {chaptersLoading ? "Loading..." :
                    <>
                        <div className="flex gap-6">
                            <h3>Topic:</h3>
                            <div>
                                <button onClick={() => {
                                    setDropdowns();
                                    setTopicDrop(prev => !prev)
                                }
                                } className="border border-black px-4 py-1">{selectedTopic === null ? "Select a Topic" : selectedTopic?.name}</button>
                                {topicDrop &&
                                    <div className="absolute border border-black bg-white">
                                        {chaptersList?.sort((a, b) => a.chapter_id - b.chapter_id).map((doc: any, index: number) => {
                                            const docData = doc;
                                            return (
                                                <h3 key={docData.chapter_id} onClick={() => { setSelectedTopic(docData); setSelectedSubTopic(null); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docData.name}</h3>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                        {selectedTopic !== null && <div className="flex gap-6">
                            <h3>Sub Topic:</h3>
                            <div>
                                <button onClick={() => {
                                    setDropdowns();
                                    setSubTopicDrop(prev => !prev)
                                }
                                } className="border border-black px-4 py-1">{selectedSubTopic === null ? "Select a SubTopic" : selectedSubTopic?.name}</button>
                                {subTopicDrop &&
                                    (selectedTopic?.topics[0]?.sub_id === undefined || selectedTopic?.topics[0]?.sub_id === null ?
                                        <div className="absolute border border-black bg-white">
                                            {selectedTopic.topics.map((subTopic: any, index: number) => {
                                                return (
                                                    <h3 key={selectedTopic.chapter_id + '_' + subTopic.id} onClick={() => { setSelectedSubTopic(subTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{subTopic.name}</h3>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="absolute">
                                            <div className="border border-black bg-white">
                                                <h2>{selectedLanguage === "English" ? "Practical" : "ಪ್ರಾಯೋಗಿಕ ಕಲಿಕೆ"}</h2>
                                                {selectedTopic?.topics.filter((subTopic) => subTopic.sub_id == 1).map((docSubTopic, topicIndex: number) => {
                                                    return (
                                                        <h3 key={selectedTopic.chapter_id + '_' + docSubTopic.id} onClick={() => { setSelectedSubTopic(docSubTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docSubTopic.name}</h3>
                                                    )
                                                })}
                                            </div>
                                            <div className="border border-black bg-white">
                                                <h2>{selectedLanguage === "English" ? "Theoretical Proofs" : "ತಾರ್ಕಿಕ ಸಾಧನೆ"}</h2>
                                                {selectedTopic?.topics.filter((subTopic) => subTopic.sub_id == 2).map((docSubTopic, topicIndex: number) => {
                                                    return (
                                                        <h3 key={selectedTopic.chapter_id + '_' + docSubTopic.id} onClick={() => { setSelectedSubTopic(docSubTopic); setDropdowns() }} className="cursor-pointer px-4 py-1 hover:bg-slate-300">{docSubTopic.name}</h3>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>}
                        {
                            selectedSubTopic !== null && selectedSubTopic !== null &&
                            <div className="flex gap-6">
                                <input type="file" onChange={(e: any) =>
                                    setggbFile(e.target.files[0])} />
                                <button onClick={handleClick} className="border border-black px-4 py-1 rounded-md hover:bg-slate-400">Upload</button>

                            </div>
                        }
                    </>
                } */}
            </div>
        )
    } else {
        return (
            <div>
                Access Denied!
            </div>
        )
    }
}

export default Admin;