import { useState } from "react";
import deleteDocumentbyID from "../../firestore/deleteDocumentByID";

const useDeleteDocument = (collection) => {
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
//   const [createdChapter, setCreatedChapter] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const deleteChapter = async (docId: any) => {
    try {
      setLoading(true);
      await deleteDocumentbyID(collection, docId)
      setDeleteFlag((prev)=>!prev)
    } catch (error) {
      console.log("Error creating chapter");
    } finally {
      setLoading(false);
    }
  };

  return { deleteFlag, deleteChapter, loading };
};

export default useDeleteDocument;
