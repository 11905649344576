import {
  getStorageReference,
  uploadStorageFile,
} from "./basicOperations";

const updateChapterGGB = async (
  filePath: string,
  fileData: any
) => {
  const storageRef = await getStorageReference("GGBFiles/"+filePath);
  await uploadStorageFile(storageRef, fileData);
};

export default updateChapterGGB;
