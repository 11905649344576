import React, { useState } from "react";
import ReactDOM from "react-dom/client";

import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import Admin from "./src/pages/Admin";
import Signin from "./src/pages/Signin";
import ErrorPage from "./src/pages/Error";
import Home from "./src/pages/Home";

import { AuthContextProvider } from "./src/context/AuthContext";
import Navbar from "./src/components/Navbar";
import Protected from "./src/components/Protected";
import TopicPage from "./src/pages/TopicPage";
import SideBar from "./src/pages/SideBar";
import Apps from "./src/pages/Apps";
import Checkout from "./src/pages/Checkout";
import Policies from "./src/pages/Policies";
import { AppsContextProvider } from "./src/context/AppsContext";

const AppLayout = () => {
  const [userOptions, setUserOptions] = useState(false);
  const [sideBar, setSideBar] = useState(false);

  const handleSideBar = () => {
    setSideBar(!sideBar);
  };

  const handleUserOptions = () => {
    setUserOptions(!userOptions);
  };

  return (
    <AuthContextProvider>
      <AppsContextProvider>
        <Navbar
          userOptions={userOptions}
          handleUserOptions={handleUserOptions}
          sideBar={sideBar}
          handleSideBar={handleSideBar}
        />
        <Outlet />
        <SideBar sideBar={sideBar} handleSideBar={handleSideBar} />
      </AppsContextProvider>
    </AuthContextProvider>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/math",
        element: (
          <Protected>
            <Home />
          </Protected>
        ),
      },
      {
        path: "/policies",
        element: <Policies />,
      },
      {
        path: "/",
        element: (
          <Protected>
            <Apps />
          </Protected>
        ),
      },
      {
        path: "/checkout",
        element: (
          <Protected>
            <Checkout />
          </Protected>
        ),
      },
      {
        path: "/admin",
        element: (
          <Protected>
            <Admin />
          </Protected>
        ),
      },
      {
        path: "/signin",
        element: <Signin />,
      },
      {
        path: "/topic",
        element: (
          <Protected>
            <TopicPage />
          </Protected>
        ),
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

// create root using createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));
// passing react element inside root
root.render(<RouterProvider router={appRouter} />);
