import { useState } from "react";
import { queryType } from "../firestore/basicOperations";
import getDocsList from "../firestore/getDocsList";

const useGetApps = () => {
  const [appsList, setAppsList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getAppsList = async (queries: queryType) => {
    try {
      setLoading(true);
      let apps = [];
      apps = await getDocsList("apps", []);
      setAppsList(apps);
    } catch (error) {
      console.log("Error fetching chapters");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return { getAppsList, chaptersList: appsList, loading };
};

export default useGetApps;
