
import React from 'react';
import { createContext, useContext, useState } from "react";


interface AppsContextType {
    app: any;
    apps: any;
    setApps: any;
    setApp: any;
    chapters: any;
    setChapters: any;
    topics: any;
    setTopics: any;
}

export const AppsContext = createContext<AppsContextType>({
    app: null,
    setApp: null,
    apps: [],
    setApps: null,
    chapters: [],
    setChapters: null,
    topics: [],
    setTopics: null,
});

export const AppsContextProvider = ({children}) => {
    const [app, setApp] = useState(null);
    const [apps, setApps] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [topics, setTopics] = useState([]);

    return (
        <AppsContext.Provider value={{ app, setApp, apps, setApps, chapters, setChapters, topics, setTopics}}>
            {children}
        </AppsContext.Provider>
    )
};