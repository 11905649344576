import { useState } from "react";
import { createDoc } from "../../firestore/createDoc";

const useCreateChapter = () => {
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [createdChapter, setCreatedChapter] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateChapterCollection = async (data: any) => {
    try {
      setLoading(true);
      setIsUpdated(false);
      setCreatedChapter(null);
      await createDoc("newChapters", data);
      setCreatedChapter(data.name);
    } catch (error) {
      setCreatedChapter(null);
      console.log("Error creating chapter");
    } finally {
      setIsUpdated(true);
      setLoading(false);
    }
  };

  return { isUpdated, createdChapter, updateChapterCollection, loading };
};

export default useCreateChapter;
